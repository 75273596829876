<template>
  <div>
    <!-- <div class="card">
      <div class="card-body">
        <div>
          <h2 class="text-dark font-medium-3">
            <i class="fa fa-search" /> ค้นหาผู้ใช้งาน
          </h2>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="username">Keyword</label>
              <input
                id="username"
                v-model="username"
                type="text"
                class="form-control"
                placeholder="ชื่อผู้ใช้งาน"
              >
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="username">ค้นหาผู้ใช้งาน</label>
              <b-form-select
                v-model="usernameSelect"
                options="user"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="dateStart">เวลาเริ่มต้น</label>
              <flat-pickr
                v-model="dateStart"
                class="form-control"
                placeholder="เลือกเวลาเริ่มต้น"
                :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="dateEnd">เวลาสิ้นสุด</label>
              <flat-pickr
                v-model="dateEnd"
                class="form-control"
                placeholder="เลือกเวลาสิ้นสุด"
                :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
              />
            </div>
          </div>
          <div class="col-md-3">
            <button class="btn btn-gradient-dark">
              ค้นหาผู้ใช้งาน
            </button>
          </div>
        </div>
      </div>
    </div> -->
    <div class="card">
      <div class="p-1">
        <div class="row">
          <div class="col d-flex align-items-center">
            <h2 class="text-dark font-medium-3">
              <i class="fa fa-users" /> รายการแนะนำเพื่อน
            </h2>
          </div>
          <div class="col d-flex justify-content-end">
            <router-link
              :to="{ path: `/Member-add` }"
              class="btn btn-gradient-primary"
            >
              <i class="fa fa-plus" /> สร้างผู้แนะนำใหม่
            </router-link>
          </div>
        </div>
      </div>
      <b-table
        hover
        responsive="sm"
        :items="zean"
        :fields="fields"
      >
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(name)="data">
          <div class="d-flex align-items-center">
            <div class="d-flex flex-column">
              <span
                class="font-weight-bold"
              >({{ data.item.zean_percent }}%) <router-link :to="{ name: 'invite-detail', params:{ id: data.item.id} }">{{
                data.item.fname
              }} <i class="fad fa-pennant text-warning" /></router-link>
              </span>
            </div>
          </div>
        </template>
        <template #cell(action)="data">
          <div class="d-flex align-items-center">
            <router-link :to="{ name: 'member-edit', params:{ id: data.item.id} }">
              <b-button
                variant="primary"
                class="mr-1"
                size="sm"
              >
                <i class="fa fa-edit" />
              </b-button>
            </router-link>
            <router-link :to="{ name: 'invite-detail', params:{ id: data.item.id} }">
              <b-button
                variant="info"
                class="mr-1"
                size="sm"
              >
                เพื่อนที่แนะนำ
              </b-button>
            </router-link>
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BTable,
  // BFormSelect,
} from 'bootstrap-vue'
// import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BButton,
    BTable,
    // BFormSelect,
    // flatPickr,
  },
  data() {
    return {
      searchBy: 'name',
      searchByOptions: [
        { value: 'name', text: 'ชื่อ' },
        { value: 'email', text: 'อีเมล' },
        { value: 'phone', text: 'เบอร์โทร' },
      ],
      fields: [
        { key: 'action', label: '' },
        { key: 'name', label: 'ผู้ใช้งาน' },
        { key: 'tel', label: 'เบอร์โทรศัพท์' },
        { key: 'memzeancount', label: 'สมัครทั้งหมด' },
        { key: 'sumamount', label: 'แทงสำเร็จทั้งหมด' },
      ],
      items: [
        {
          action: '',
          name: 'John Doe',
          username: 'johndoe',
          register_all: '0',
          register_depo: '0',
          win_lose: '0',
        },
      ],
      zean: [],
    }
  },
  mounted() {
    this.getUserslistzean()
  },
  methods: {
    getUserslistzean() {
      this.$http
        .get('https://staffapi.ma5lotto.com/api/users/listzean')
        .then(response => {
          this.zean = response.data
          console.log(this.zean)
        })
        .catch(error => console.log(error))
    },
  },
}
</script>

<style scoped>
.search-1 {
  padding: 1rem;
  background: #000;
}
thead th {
  background: #000 !important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
